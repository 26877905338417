/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ImageControl from "../components/imageControl";
import { onlyUnique } from "../components/navbar";
import { Product } from "../layout";

export interface CategoriesPageProps {
  match: any;
  products: Product[];
}

export const CategoriesPage = (props: CategoriesPageProps) => {
  const [categories, setCategories] = useState<string[]>([]);
  const [rowElements, setRowElements] = useState<any[]>([]);

  useEffect(() => {
    let c = props.products.map((x) => x.category).filter(onlyUnique);
    setCategories(c);
  }, []);

  useEffect(() => {
    let rows: number = Math.ceil(categories.length / 3);
    let rowElements: any[] = [];
    for (let i = 1; i <= rows; i++) {
      rowElements.push(
        <div key={i} className="row text-center mb-5">
          {getColElements(i)}
        </div>
      );
    }
    setRowElements(rowElements);
  }, [categories]);

  const getColElements = (i: number) => {
    const colElements = [];
    let count: number = i * 3;
    let start: number = count - 3;

    if (count > categories.length) {
      start = categories.length - (3 - (count - categories.length));
      count = categories.length;
    }

    for (let j = start; j < count; j++) {
      let filteredProduct = props.products.find(
        (x) => x.category === categories[j]
      );
      let imageFolder = filteredProduct.imageFolder;
      colElements.push(
        <div key={j} className="col-md-4">
          {categories[j] ? (
            <a
              href={`/categories/category/${categories[j]
                .toLowerCase()
                .replace(" ", "-")}`}
            >
              <ImageControl
                caption={categories[j]}
                imageUrl={`/images/${imageFolder}/1.jpg`}
                corner="rounded"
                className="img-thumbnail"
              />
            </a>
          ) : (
            ""
          )}
        </div>
      );
    }
    return colElements;
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Categories</title>
        <meta name="description" content="Browse our categories" />
      </Helmet>

      <div className="theme theme-yellow">
        <div className="container">
          <div className="row">
            <div className="col">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Categories
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <h1>Categories</h1>
              <div className="h1Divider"></div>
              <br />
              <p>Browse our categories.</p>
            </div>
            <div className="col-md-4 d-none d-md-block text-right"></div>
          </div>
        </div>
      </div>

      <div className="theme theme-white projects">
        {rowElements && <div className="container">{rowElements}</div>}
      </div>
    </>
  );
};
