/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ImageControl from "../components/imageControl";
import { onlyUnique } from "../components/navbar";
import { Product } from "../layout";
import * as lodash from "lodash";

export interface CategoryPageProps {
  match: any;
  products: Product[];
}

export const CategoryPage = (props: CategoryPageProps) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [rowElements, setRowElements] = useState<any[]>([]);

  useEffect(() => {
    let p = props.products.filter(
      (x) => x.category.toLowerCase() === props.match.params.id
    );
    setProducts(p);
  }, []);

  useEffect(() => {
    let rows: number = Math.ceil(products.length / 3);
    let rowElements: any[] = [];
    for (let i = 1; i <= rows; i++) {
      rowElements.push(
        <div key={i} className="row text-center mb-5">
          {getColElements(i)}
        </div>
      );
    }
    setRowElements(rowElements);
  }, [products]);

  const getColElements = (i: number) => {
    const colElements = [];
    let count: number = i * 3;
    let start: number = count - 3;

    if (count > products.length) {
      start = products.length - (3 - (count - products.length));
      count = products.length;
    }

    for (let j = start; j < count; j++) {
      colElements.push(
        <div key={j} className="col-md-4">
          {products[j] ? (
            <a
              href={`/product/${products[j].title
                .toLowerCase()
                .replace(/ /g, "-")}`}
            >
              <ImageControl
                caption={products[j].title}
                imageUrl={`/images/${products[j].imageFolder}/1.jpg`}
                corner="rounded"
                className="img-thumbnail"
              />
            </a>
          ) : (
            ""
          )}
        </div>
      );
    }
    return colElements;
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Products in {props.match.params.id}</title>
        <meta name="description" content="Products" />
      </Helmet>

      <div className="theme theme-yellow">
        <div className="container">
          <div className="row">
            <div className="col">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <a href="/categories">Categories</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {lodash.capitalize(props.match.params.id)}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <h1>{lodash.capitalize(props.match.params.id)}</h1>
              <div className="h1Divider"></div>
              <br />
              <p>Browse our products for {props.match.params.id}.</p>
            </div>
            <div className="col-md-4 d-none d-md-block text-right"></div>
          </div>
        </div>
      </div>

      <div className="theme theme-white projects">
        {rowElements && <div className="container">{rowElements}</div>}
      </div>
    </>
  );
};
