/* eslint-disable jsx-a11y/alt-text */
import React from "react";

export interface ImageControlProps {
    imageUrl: string;
    className?: string;
    alt?: string;
    caption?: string;
    corner?: "rounded" | "";
}

interface ImageControlState {
    imageStatus: string;
}

export default class ImageControl extends React.Component<
    ImageControlProps,
    ImageControlState
> {
    constructor(props) {
        super(props);
        this.state = { imageStatus: "d-none" };
    }

    handleImageLoaded() {
        this.setState({ imageStatus: "d-block" });
    }

    handleImageErrored() {
        this.setState({ imageStatus: "d-none" });
    }

    render() {
        return (
            <div>
                <img
                    src='/images/placeholder.gif'
                    className={
                        this.state.imageStatus === "d-block"
                            ? "d-none"
                            : "img-fluid img-thumbnail"
                    }
                /> 

                <figure className='figure'>
                    <img
                        src={this.props.imageUrl}
                        onLoad={this.handleImageLoaded.bind(this)}
                        onError={this.handleImageErrored.bind(this)}
                        alt={this.props.alt ? this.props.alt : ""}
                        className={`mb-0 figure-img img-fluid ${
                            this.props.corner ? this.props.corner : ""
                        } ${this.props.className ? this.props.className : ""} ${
                            this.state.imageStatus === "d-block" ? "" : "d-none"
                        }`}
                    />
                    {this.props.caption ? (
                        <figcaption className='figure-caption'>
                            {this.props.caption}
                        </figcaption>
                    ) : (
                        ""
                    )}
                </figure>
            </div>
        );
    }
}
