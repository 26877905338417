import * as React from "react";
import { Helmet } from "react-helmet";
import BannerControl from "../components/banner";

export const WholesalePage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Wholesale | Hauser ARTZ Australia</title>
        <meta name="description" content="Welcome to Hauser Artz Australia" />
      </Helmet>
      <div className="theme theme-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Wholesale products</h1>
              <div className="h1Divider"></div>
              <br />
              <p>Thanks for you interest in stocking our products.</p>
              <div className="row">
                <div className="col"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="theme theme-grey pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Why wholesale?</h2>
              <hr />
              <br />

              <ul>
                <li>Get a cheaper price by becoming a reseller.</li>
                <li>
                  Get further discounts by visiting our wholesale link at{" "}
                  <a
                    href="https://www.tradesquare.com.au/marketplace/shop/view/id/3020/#u3020"
                    target="_blank"
                  >
                    Tradesquare
                  </a>
                </li>
                <li>
                  Use the code <b>TSHAUSARTZ100</b> to get an instant $100
                  discount when you shop wholesale.
                </li>
              </ul>
            </div>
            <div className="col-md-6 text-right">
              <h1>
                <span className="fas fa-tags fa-7x"></span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
