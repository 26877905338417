/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ImageControl from "../components/imageControl";
import { onlyUnique } from "../components/navbar";
import { Product } from "../layout";
import * as lodash from "lodash";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import parse from "html-react-parser";

export interface ProductPageProps {
  match: any;
  products: Product[];
}

export const ProductPage = (props: ProductPageProps) => {
  const [product, setProduct] = useState<Product>();
  const [images, setImages] = useState<ReactImageGalleryItem[]>([]);

  useEffect(() => {
    let product = props.products.find(
      (x) => x.title.replace(/ /g, "-").toLowerCase() === props.match.params.id
    );
    setProduct(product);
    let imageCount: number = parseInt(product.imageCount);
    let images: ReactImageGalleryItem[] = [];
    for (let i = 1; i <= imageCount; i++) {
      let image: ReactImageGalleryItem = {
        original: `/images/${product.imageFolder}/${i}.jpg`,
        thumbnail: `/images/${product.imageFolder}/${i}.jpg`,
      };
      images.push(image);
    }
    setImages(images);
  }, []);

  function NewlineText(props) {
    const text = props.text;
    return text.split("\n").map((str) => <p>{str}</p>);
  }

  if (!product) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>{product.title}</title>
        <meta name="description" content="Products" />
      </Helmet>

      <div className="theme theme-product">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <a href="/categories">Categories</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <a
                  href={`/categories/category/${product.category
                    .toLowerCase()
                    .replace(/ /g, "-")}`}
                >
                  {product.category}
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {lodash.capitalize(product.title)}
              </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-md-12">
              <h1>{lodash.capitalize(product.title)}</h1>
              <div className="h1Divider"></div>
              <br />
            </div>
            <div className="col-md-4 d-none d-md-block text-right"></div>
          </div>
          <div className="row">
            <div className="col">
              <ImageGallery items={images} />
            </div>
            <div className="col">
              <div className="row">
                <div className="col">
                  <span className="tag">${product.price}</span>
                  <br />
                  <br />
                  <p>{lodash.capitalize(product.title)}</p>
                  <p>
                    {product.catchUrl && (
                      <a
                        href={product.catchUrl}
                        target="_blank"
                        className="btn btn-secondary btn-lg active btn-block"
                        role="button"
                        aria-pressed="true"
                      >
                        Buy now
                      </a>
                    )}
                    {!product.catchUrl && (
                      <a
                        href="javascript:void(Tawk_API.toggle())"
                        className="btn btn-secondary btn-lg active btn-block"
                        role="button"
                        aria-pressed="true"
                      >
                        Enquire now
                      </a>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="row mt-5">
            <div className="col">
              <h2>Description</h2>
              <hr />
              <p className="description">
                <NewlineText text={product.description} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
