/* eslint-disable react/jsx-no-target-blank */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { NotFoundPage } from "./Pages/notfound";
import { Navbar } from "./components/navbar";
import { HomePage } from "./Pages/home";

import ReactGA from "react-ga";
import { ContactusPage } from "./Pages/contactus";

import * as d3 from "d3-dsv";
import { CategoriesPage } from "./Pages/categories";
import { CategoryPage } from "./Pages/category";
import { ProductPage } from "./Pages/product";
import { WholesalePage } from "./Pages/wholesale";

export interface Product {
  category: string;
  title: string;
  price: string;
  imageFolder: string;
  imageCount: string;
  catchUrl: string;
  description: string;
}

export const Layout = () => {
  const [products, setProducts] = useState<Product[]>();
  useEffect(() => {
    readData();
    initialiseReactGA();
  }, []);

  const readData = async () => {
    let response = await fetch("/data/items.csv");
    let data = await response.text();
    let csv: Product[] = d3.csvParse(data, (d) => {
      return {
        category: d.category,
        title: d.title,
        description: d.description,
        price: d.price,
        imageFolder: d.imageFolder,
        imageCount: d.imageCount,
        catchUrl: d.catchUrl,
      };
    });
    setProducts(csv);
    console.log(csv);
  };

  const initialiseReactGA = () => {
    if (process.env.REACT_APP_GA) {
      ReactGA.initialize(process.env.REACT_APP_GA);
      ReactGA.pageview(window.location.pathname + window.location.search);

      ReactGA.ga("create", process.env.REACT_APP_GA, "auto");
      ReactGA.ga("require", "eventTracker");
      ReactGA.ga("require", "outboundLinkTracker");
      ReactGA.ga("require", "urlChangeTracker");
      ReactGA.ga("send", "pageview");

      $("a").click(function () {
        let eventName = $(this).text();
        let url = $(this).attr("href");
        ReactGA.event({
          category: "Links",
          action: url,
          label: eventName,
        });
      });
      $("button").click(function () {
        ReactGA.event({
          category: "Button clicks",
          action: window.location.pathname,
          label: $(this).text(),
        });
      });

      $(document).on("change", "select", function () {
        ReactGA.event({
          category: "Dropdown clicks",
          action: window.location.pathname,
          label: $(this).val().toString(),
        });
      });
    }
  };

  return (
    <>
      <div className="holder">
        <div className="wrapper">
          <div className="theme-menu">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {products && <Navbar products={products} />}
                </div>
              </div>
            </div>
          </div>
          <div className="theme-white header">
            <div className="container">
              <div className="row">
                <div className="col-6">
                  <div className="row banner">
                    <div className="col-3">
                      <a href="/" className="logo">
                        <img
                          className=""
                          src="/images/logo.png"
                          alt="hauserartz.com.au"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-6 mt-3 text-right">
                  <a
                    className="ml-2 mail"
                    href="mailto:info@hauserartz.com.au"
                    title="info@hauserartz.com.au"
                  >
                    <i className="fas fa-envelope-square fa-2x"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/wholesale" component={WholesalePage} />
            <Route exact path="/contact-us" component={ContactusPage} />
            {products && (
              <Route
                exact
                path="/categories"
                render={({ match }) => (
                  <CategoriesPage match={match} products={products} />
                )}
              />
            )}

            {products && (
              <Route
                exact
                path="/categories/category/:id"
                render={({ match }) => (
                  <CategoryPage match={match} products={products} />
                )}
              />
            )}

            {products && (
              <Route
                exact
                path="/product/:id"
                render={({ match }) => (
                  <ProductPage match={match} products={products} />
                )}
              />
            )}

            <Route component={() => <NotFoundPage />} />
          </Switch>

          <div className="theme theme-footer">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2>Info@hauserartz.com.au</h2>
                  <div className="h1Divider"></div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4">
                  <h3>General</h3>
                  <ul>
                    <li>
                      <a href="/categories">Products</a>
                    </li>

                    <li>
                      <a href="/contact-us">Contact us</a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-8">
                  <h3>Contact us</h3>
                  <ul className="fa-ul pl-3">
                    <li>
                      <i className="fa-li fas fa-envelope"></i>{" "}
                      <a href="mailto:info@hauserartz.com.au">
                        info@hauserartz.com.au
                      </a>
                    </li>
                    <li>
                      <i className="fa-li fas fa-map-marker-alt"></i>
                      <span>Bonner ACT 2914</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-light spacer"></div>
      <div className="theme theme-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-8 text-center text-md-left">
              2021 <i className="fas fa-copyright"></i> Copyright
              hauserartz.com.au. All rights Reserved
            </div>
            <div className="col-md-4 powered-by text-center text-md-right">
              <label>Powered by </label>
              <a href="https://acader.com.au" target="_blank">
                <img
                  src="/images/acader_logo.png"
                  alt="Acader pty ltd"
                  className="img-fluid ml-2"
                ></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
