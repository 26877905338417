import React, { useEffect, useState } from "react";
import { Product } from "../layout";

export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export interface NavbarProps {
  products: Product[];
}

export const Navbar = (props: NavbarProps) => {
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    let c = props.products.map((x) => x.category).filter(onlyUnique);
    setCategories(c);
  }, []);

  const iamActive = (href: string) => {
    let url = window.location.href;
    return url.indexOf(href) !== -1;
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo01"
          aria-controls="navbarTogglerDemo01"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
            <li className={`nav-item ${iamActive("/") ? "active" : ""}`}>
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Products
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                {categories &&
                  categories.filter(onlyUnique).map((x, i) => {
                    return (
                      <div key={i}>
                        <a
                          className="dropdown-item"
                          href={`/categories/category/${x
                            .replace(/ /g, "-")
                            .toLowerCase()}`}
                        >
                          {x}
                        </a>
                        {i < categories.length - 1 && (
                          <div className="dropdown-divider"></div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </li>
            <li
              className={`nav-item ${iamActive("/wholesale") ? "active" : ""}`}
            >
              <a className="nav-link" href="/wholesale">
                Wholesale
              </a>
            </li>
            <li
              className={`nav-item ${iamActive("/contact-us") ? "active" : ""}`}
            >
              <a className="nav-link" href="/contact-us">
                Contact us
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};
