import React, { useEffect } from "react";

export const NotFoundPage = () => {
  useEffect(() => {
    document.title = "Not Found";
  }, []);

  return (
    <>
      <div className="theme-white">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center p-5">
              <h1>
                <i className="fas fa-wrench mr-2"></i>404 Page not found
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
