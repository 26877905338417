import * as React from "react";
import { Helmet } from "react-helmet";
import BannerControl from "../components/banner";

export const HomePage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8"></meta>
        <title>Welcome to Hauser ARTZ Australia</title>
        <meta name="description" content="Welcome to Hauser Artz Australia" />
      </Helmet>
      <BannerControl />
      <div className="theme theme-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Browse popular items</h1>
              <div className="h1Divider"></div>
              <br />
              <div className="row popular text-center">
                <div className="col-md-4">
                  <a href="/categories/category/bundles">
                    <img
                      src="images/bundle.jpg"
                      className="img-thumbnail"
                      alt="Bundles"
                    />
                  </a>
                  <b className="mt-4">
                    <a href="/categories/category/bundles">Bundles</a>
                  </b>
                </div>
                <div className="col-md-4">
                  <a href="/categories/category/school-kits">
                    <img
                      src="images/school-kits/1.jpg"
                      className="img-thumbnail"
                      alt="School kits"
                    />
                  </a>
                  <br />
                  <b className="mt-4">
                    <a href="/categories/category/school-kits">School kits</a>
                  </b>
                </div>
                <div className="col-md-4">
                  <a href="/categories/category/pens">
                    <img
                      src="images/pens.jpg"
                      className="img-thumbnail"
                      alt="Pens"
                    />
                  </a>
                  <br />
                  <b className="mt-4">
                    <a href="/categories/category/pens">Pens</a>
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
